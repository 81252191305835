:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Mulish';
}

body {
  line-height: 1.35 !important;
  font-family: 'Mulish';
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: @font-size-lg;
}

h5 {
  font-size: @font-size-base;
}

h6 {
  font-size: @font-size-base;
}

.ant-form-item-explain-error {
  padding: 4px 16px;
  background-color: #FFF1F0;
  color: #FF4D4F;
  font-weight: 400;
  line-height: 22px;
  margin: 4px 0;
  border-radius: 8px;
}

.ant-input-affix-wrapper {
  font-size: 14px;
  padding: 14px;
}


.ant-form-item-label {
  padding-bottom: 0 !important;

  & label {
    height: 22px;
  }
}

.ant-form-item {
  margin-bottom: 12px;
  & .ant-row {
    flex-direction: column;
    align-items: flex-start;

    & .ant-form-item-control {
      width: 100%;
    }
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar {
  width: 5px;
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-message {
  z-index: 99999;
}