@import '../../../shared/styles/variable';

.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 24px;
  min-height: auto;

}

& .main-content-view-full {
  padding: 0px;
  background-color: #313131;
  width: 100vw;
  height: 100vh;
  padding: 24px;
}
@primary-color: #0A8FDC;