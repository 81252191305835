@import '../../../../../shared/styles/variable';
@import '../../../../../shared/styles/mixin';

.cr-user-info {
  background: @header-bg-color !important;
  height: 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .transition(all .2s ease);

  & .ant-dropdown-link {
    color: inherit;

    & .anticon {
      font-size: @font-size-sm;
    }
  }

  &.light {
    & .ant-dropdown-link {
      color: inherit;
    }

    & .cr-user-name {
      color: inherit;
    }
  }
}

.cr-user-info-inner {
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.cr-user-info-avatar {
  font-size: 24px;
  background-color: @orange-6;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cr-user-info-content {
  min-width: 60px;
  max-width: 100px;
  margin-left: 12px;
  .transition(all .2s ease);
  display: none;

  [dir=rtl] & {
    margin-left: 0;
    margin-right: 16px;
  }

  @media screen and (min-width: @screen-md) {
    display: block;
  }
}

.cr-user-name-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cr-user-name {
  margin-bottom: 0;
  font-size: @font-size-lg;
  font-weight: @font-weight-medium;
  color: black !important;
}

.cr-user-arrow {
  margin-left: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  [dir=rtl] & {
    margin-left: 0;
    margin-right: 12px;
  }

  & svg {
    display: block;
  }
}

.cr-user-designation {
  margin-top: -2px;
  color: inherit;

  .ant-layout-sider-dark & {
    color: inherit;
  }
}

.user-info-menu-items {
  background: @header-bg-color;

  & .ant-dropdown-menu-item {
    padding: 0;
    white-space: normal;

    &:hover {
      background-color: transparent;
    }

    &.header {
      padding: 12px 20px;
      font-weight: @font-weight-medium;
      cursor: inherit;

      &:hover, &:focus {
        background-color: transparent;
      }
    }
  }
}

.dropdown-list {
  background: @header-bg-color;
  color: @text-color-white;
  
  & .option-user-item {
    padding: 8px 12px;
    color: @text-color-white;
    cursor: pointer;

    // &:hover {
    //   background-color: darken(@header-bg-color, 3);
    // }
  }
}

.cr-user-info-hasColor {
  background: transparent !important;

  & .ant-dropdown-link {
    color: inherit;
  }

  &.light {
    & .ant-dropdown-link {
      color: inherit;
    }

    & .cr-user-name {
      color: inherit;
    }
  }

  & .cr-user-designation {
    color: inherit;

    .ant-layout-sider-dark & {
      color: inherit;
    }
  }

  & .cr-user-name {
    color: inherit;

    &.light {
      color: inherit;
    }
  }
}
@primary-color: #0A8FDC;