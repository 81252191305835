@import '../../../shared/styles/variable';
@import '../../../shared/styles/mixin';

[data-simplebar] {
  position: relative;
  width: 100%;
  height: calc(100vh - 28px);
  padding-bottom: 16px;

  & .simplebar-offset,
  & .simplebar-content-wrapper,
  & .simplebar-content {
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-bottom: 16px;
  }
}
@primary-color: #0A8FDC;