@import '../../../../../shared/styles/variable';
@import '../../../../../shared/styles/mixin';

// Sidebar Menu
.app-main-sidebar-menu {

  & .ant-menu-inline {
    background: transparent !important;
  }

  &.ant-menu,
  & .ant-menu-sub.ant-menu-inline {
    background-color: inherit;
  }

  & .ant-menu-item {
    margin-top: 0;
  }

  .ant-menu-item-selected {
    background-color: white !important;
    color: @sidebar-dark-bg-color !important;
    font-weight: 600 !important;

    & .ant-menu-title-content {
      color: @sidebar-dark-bg-color !important;
      font-weight: 600 !important;
    }

    & img {
      filter: invert(90%) sepia(91%) saturate(5890%) hue-rotate(205deg) brightness(99%) contrast(100%)
    }
  }
}
@primary-color: #0A8FDC;