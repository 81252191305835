@import '../../shared/styles/variable';

.receive-call-container {
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  background: #3131315d;
  height: 100vh;
  z-index: 9999;
  top: 0;

  & .call-video-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  & .box-call-video {
    max-width: 320px;
    width: 100%;
    border-radius: 20px;
    padding: 24px;
    background-color: #0049C6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    & .text-waiting {
      margin-top: 24px;
      color: white;
      font-size: 14px;
    }
  }

  & .box-image {
    animation: mymove 3s infinite cubic-bezier(.36, .11, .89, .32);
    border-radius: 100%;
    min-width: 82px;
    min-height: 82px;
    background-color: #C4C4C4;
    border: 2px solid #fff;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }

  & .box-image-item {
    border: 2px solid #0049C6;
    min-width: 82px;
    min-height: 82px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  & .box-call-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    & .box-call-info-item {
      min-width: 320px;
      border-radius: 8px;
      padding: 24;
      background-color: #0049C6;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      & .cr-user-info-avatar {
        border: 2px solid #FFFFFF;
      }

      & .name-customer {
        font-size: 18;
        color: white;
        line-height: 23px;
        font-weight: 700;
        margin-top: 8px;
        text-transform: capitalize;
      }

      & .list-action {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 14px;

        & .accept-btn {
          color: #0049C6;
          font-size: 14px;
          font-weight: 600;
          padding: 12px;
          width: 100%;
          margin: 0 8px;
          border-radius: 8px;
        }
      }
    }
  }

  & .refuse-btn {
    background-color: #FF754C;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    margin: 0 8px;
    border-radius: 8px;
    color: white;
  }
}

.video-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  top: 0;
  left: 0;
  background: #1E1E1E;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .video-item {
    width: 100%;
    height: 100%;
    max-width: 800px;
    max-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 12px;

    & .video-me {
      height: 100%;
      width: 100%;
      min-width: 50px;
      min-height: 60px;
      position: absolute;
      z-index: 50;
      border-radius: 12px;
      overflow: hidden;
      border: 1px solid white;
    }

    .video-you {
      height: 100%;
      width: 100%;
      min-width: 200px;
      min-height: 220px;
      position: absolute;
      right: 24px;
      bottom: 24px;
      z-index: 40;
      border-radius: 12px;
      border: 2px solid white;
      overflow: hidden;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      transform: rotateY(180deg);
      -webkit-transform: rotateY(180deg);
      /* Safari and Chrome */
      -moz-transform: rotateY(180deg);
      /* Firefox */
    }
  }
}

@keyframes mymove {
  30% {
    box-shadow: 0 0 30px white;
  }
}
@primary-color: #0A8FDC;